import commonHelper from '@/app/utility/common.helper.utility';
import AddFmsTimesheetSummary from './addFmsTimesheetSummary';
import EditFmsTimesheetSummary from './editFmsTimesheetSummary';
import appStrings from '@/app/utility/string.utility';
import { startOfMonth, endOfMonth } from 'date-fns';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'FmsTimesheetSummary',
  components: {
    DatePicker,
    AddFmsTimesheetSummary,
    EditFmsTimesheetSummary,
    UploadDocument,
    openKmAuthentication
  },
  watch: {
    currentPage: function() {
      this.getFmsTimesheetSummaryList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsTimesheetSummaryList();
    },
    month: function() {
      this.customMonthFunction();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      parent_value_set_id: null,
      depVsetParam: null,
      showValueSetModal: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      showAlert: false,
      editMode: false,
      payload: null,
      project: {
        id: null,
        text: null
      },
      salesAgreement: {
        id: null,
        text: null
      },
      month: null,
      status: {
        id: null,
        text: null
      },
      defaultValue: {
        id: null,
        text: null
      },
      timesheetSummaryData: [],
      timesheetSummaryFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_name'
        },
        {
          key: 'bill_to_address',
          label: 'Address'
        },
        {
          key: 'year'
        },
        {
          key: 'month'
        },
        {
          key: 'fms_prj_name'
        },
        {
          key: 'sales_agreement_num',
          label: 'Sales Agmt No.'
        },
        {
          key: 'version'
        },
        {
          key: 'status_meaning',
          label: 'Timesheet Status'
        },
        {
          key: 'remarks'
        },
        {
          key: 'request_date'
        },
        {
          key: 'billing_status_meaning',
          label: 'Billing Status'
        }
      ],
      firstDayOfTheMonth: null,
      lastDayOfTheMonth: null,
      showAddTimesheetSummaryModal: false,
      showEditTimesheetSummaryModal: false,
      selectedTimesheetSummaryData: null,
      addMode: true,
      selectedHdrId: null,
      showHistoryBtn: false,
      historyType: null,
      showHistory: false,
      showOpenKmModal: false,
      timeSheetSummaryHdrId: null,
      selectedGridData: null,
    };
  },
  validations: {
    project: {
      text: { required }
    },
    salesAgreement: {
      text: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && this.addMode) {
          this.showAddTimesheetSummaryModal = true;
        }
        if (actionName === 'delete' && this.selectedHdrId && this.addMode) {
          let toDelete = confirm('Do you want to delete this record ?');
          if (toDelete) {
            this.deleteFmsTimesheetSummaryByHdrId();
          }
        }
      }
    });
  },
  methods: {
    checkOpenKmPasswordSaved() {
      // if (this.timeSheetSummaryHdrId()) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/checkSavedPassword')
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (response.data.data) {
                this.getOpenKmAuthToken();
              } else {
                this.showHideOpenKmModal(true);
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      // }
    },
    showHideHistory(flag, historyType) {
      // if (this.timeSheetSummaryHdrId()) {
        this.showHistory = flag;
        this.historyType = historyType;
      // }
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedTimesheetSummaryData,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedTimesheetSummaryData,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    getFmsTimesheetSummaryList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _limit: this.perPage,
          _page: this.currentPage - 1,
          fms_prj_id: this.project.id,
          sales_agreement_hdr_id: this.salesAgreement.id,
          date_from: this.firstDayOfTheMonth,
          date_to: this.lastDayOfTheMonth,
          status: this.status.id
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTimesheet/getFmsTimesheetSummaryList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.timesheetSummaryData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
      }
    },
    customMonthFunction() {
      if (this.month) {
        this.firstDayOfTheMonth = commonHelper.formattedDate(
          startOfMonth(this.month)
        );
        this.lastDayOfTheMonth = commonHelper.formattedDate(
          endOfMonth(this.month)
        );
      } else {
        this.firstDayOfTheMonth = null;
        this.lastDayOfTheMonth = null;
      }
    },
    selectedRadio(item) {
      if(item.status === 'U'){
        this.selectedHdrId = item.timesheet_summary_hdr_id;
      }
      this.timeSheetSummaryHdrId = item.timesheet_summary_hdr_id;
      this.showHistoryBtn = true;
      this.selectedTimesheetSummaryData = Object.assign({}, item);
    },
    deleteFmsTimesheetSummaryByHdrId() {
      const payload = {
        timesheet_summary_hdr_id: this.selectedHdrId
      };
      this.$store
        .dispatch('fmsTimesheet/deleteFmsTimesheetSummaryByHdrId', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.selectedHdrId = null;
            this.getFmsTimesheetSummaryList();
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY
      ) {
        this.parent_value_set_id = this.project.id;
        this.depVsetParam = this.$store.state.auth.userId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreement = this.defaultValue;
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY
      ) {
        this.salesAgreement = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TIMESHEET_STATUS) {
        this.status = {
          id: item.value_code,
          text: item.value_meaning
        };
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = this.defaultValue;
        this.salesAgreement = this.defaultValue;
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY
      ) {
        this.salesAgreement = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TIMESHEET_STATUS) {
        this.status = this.defaultValue;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideAddTimesheetSummaryModal() {
      this.showAddTimesheetSummaryModal = false;
    },
    rowSelected(item) {
      this.selectedTimesheetSummaryData = item;
      this.showEditTimesheetSummaryModal = true;
      this.addMode = false;
    },
    hideEditTimesheetSummaryModal() {
      this.addMode = true;
      this.showEditTimesheetSummaryModal = false;
    },
    clearFilters() {
      this.project = this.defaultValue;
      this.salesAgreement = this.defaultValue;
      this.status = this.defaultValue;
      this.month = null;
      this.timesheetSummaryData = [];
      this.totalRows = null;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
