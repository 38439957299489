import commonHelper from '@/app/utility/common.helper.utility';
import uploadExcel from '@/app/components/shared/uploadExcel';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'editFmsTimesheetSummary',
  props: ['selectedTimesheetSummaryData'],
  components: {
    DatePicker,
    uploadExcel
  },
  watch: {
    currentPage: function () {
      this.getFmsTimesheetSummaryDetailsById();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getFmsTimesheetSummaryDetailsById();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      depVsetParam: null,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      salesAgmtNo: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      customer: {
        value: null,
        text: null
      },
      customerSite: {
        value: null,
        text: null
      },
      address: null,
      remarks: null,
      status: {
        value: null,
        text: null
      },
      billingStatus: {
        value: null,
        text: null
      },
      version: null,
      timesheetPeriod: null,
      defaultValue: {
        value: null,
        text: null
      },
      payload: {},
      showUploadExcel: false,
      timesheetSummaryData: [
        {
          employee_type_meaning: null,
          employee_type: null
        }
      ],
      timesheetSummaryFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'add_details'
        },
        {
          key: 'remove_details'
        },
        {
          key: 'billing_plan_name',
          label: 'Billing Plan'
        },
        {
          key: 'sa_comp_name_real',
          label: 'SA Comp Name'
        },
        {
          key: 'sa_comp_name',
          label: 'SA Comp Desc'
        },
        {
          key: 'sa_comp_dtl_id',
          label: 'SA Component Dtl Id'
        },
        {
          key: 'designation'
        },
        {
          key: 'employee_code'
        },
        {
          key: 'employee_name'
        },
        {
          key: 'billing_working_days',
          variant: 'info'
        },
        {
          key: 'billing_monthly_off_days',
          variant: 'info'
        },
        {
          key: 'billing_monthly_holidays',
          variant: 'info'
        },
        {
          key: 'billing_total_paid_days',
          label: 'Billing Paid Days',
          variant: 'info'
        },
        {
          key: 'billing_overtime_days',
          variant: 'info'
        },
        {
          key: 'billing_comb_paid_days',
          label: 'Billing Total Paid Days',
          variant: 'info'
        },
        {
          key: 'billing_remarks',
          variant: 'info'
        },
        {
          key: 'shift_name'
        },
        {
          key: 'shift_hour'
        },
        {
          key: 'employee_type_meaning',
          label: 'Employee Type'
        },
        {
          key: 'salary_working_days',
          variant: 'success'
        },
        {
          key: 'salary_monthly_off_days',
          variant: 'success'
        },
        {
          key: 'salary_monthly_holidays',
          variant: 'success'
        },
        {
          key: 'salary_total_paid_days',
          label: 'Salary Paid Days',
          variant: 'success'
        },
        {
          key: 'salary_overtime_days',
          variant: 'success'
        },
        {
          key: 'salary_comb_paid_days',
          label: 'Salary Total Paid Days',
          variant: 'success'
        },
        {
          key: 'salary_remarks',
          variant: 'success'
        },
        {
          key: 'arrear_paid_days',
          variant: 'success'
        },
        {
          key: 'arrear_overtime_days',
          variant: 'success'
        },
        {
          key: 'arrear_total_paid_days',
          variant: 'success'
        },
        {
          key: 'arrear_remarks',
          variant: 'success'
        },
        {
          key: 'bill_num',
          label: 'Final Bill Number'
        }
      ],
      customerSiteName: null,
      monthName: null,
      summaryData: null,
      summaryFields: [
        {
          key: 'desc',
          label: 'Description'
        },
        {
          key: 'billing_total_paid_days'
        },
        {
          key: 'billing_overtime_days'
        },
        {
          key: 'salary_total_paid_days'
        },
        {
          key: 'salary_overtime_days'
        },
      ]
    };
  },
  mounted() {
    if (this.selectedTimesheetSummaryData) {
      this.timesheetSummaryHdrId = this.selectedTimesheetSummaryData.timesheet_summary_hdr_id;
      this.getFmsTimesheetSummaryHdrById();
      this.getSummaryData();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        const toUpdate = this.status.value === 'U' && this.editMode;
        if (actionName === 'save' && toUpdate) {
          // this.editTimesheetSummaryRemarks();
          this.editTimesheetSummaryDetails();
        }
        if (actionName === 'approval') {
          let processUnprocess = this.status.value === 'U' ? 'Process' : 'Unprocess';
          let toProcess = confirm(`Do You Want to ${processUnprocess} this Timesheet.`);
          if (toProcess) {
            this.fmsTimesheetProcessUnProcess();
          }
        }
        if (actionName === 'delete' && toUpdate) {
          let toProcess = confirm('Do you want to delete these records?');
          if (toProcess) {
            this.deleteTimesheetSummaryDetailByLineId();
          }
        }
        if (actionName === 'download') {
          this.loader = true;
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fmsTimesheet/downloadFmsTimesheetSummaryDetailsById',
            this.customerSiteName + '(' +this.monthName+')',
            () => (this.loader = false)
          );
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload' && this.status.value === 'U') {
          this.showUploadExcel = true;
        }
      }
    });
  },
  methods: {
    copyBillingToSalaryData(item, value, type) {
      if (type === 'billing_working_days') {
        item.salary_working_days = value
      } else if (type === 'billing_monthly_off_days') {
        item.salary_monthly_off_days = value
      } else if (type === 'billing_monthly_holidays') {
        item.salary_monthly_holidays = value
      } else if (type === 'billing_total_paid_days') {
        item.salary_total_paid_days = value
      } else if (type === 'billing_overtime_days') {
        item.salary_overtime_days = value
      }
    },
    checkDuplicateEmpCode(code, item) {
      let count = 0;
      for (let i of this.timesheetSummaryData) {
        if (i !== item && i.employee_code === code && i.employee_code) {
          count = count + 1;
        }
      }
      if (count > 0) {
        this.$bvToast.toast('Employee Code is Duplicate', {
          title: 'Alert',
          variant: 'danger',
          autoHideDelay: 100,
          solid: true,
          toaster:'b-toaster-top-center',
        });
      }
    },
    getSummaryData() {
      const payload = {
        timesheet_summary_hdr_id: this.timesheetSummaryHdrId,
        }
        this.loader = true;
        this.$store
          .dispatch('fmsTimesheet/getSummaryData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.summaryData = response.data.data;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
    },
    getFmsTimesheetSummaryHdrById() {
      this.loader = true;
      this.$store
        .dispatch(
          'fmsTimesheet/getFmsTimesheetSummaryHdrById',
          this.timesheetSummaryHdrId
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerSiteName = response.data.data[0].customer_site_name;
            this.monthName = response.data.data[0].month;
            const result = response.data.data[0];
            this.setTimesheetSummaryHdrDetails(result);
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    setTimesheetSummaryHdrDetails(item) {
      this.project = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
      this.salesAgmtNo = {
        value: item.sales_agreement_hdr_id,
        text: item.sales_agreement_num
      };
      this.customer = {
        id: item.customer_id,
        name: item.customer_name
      };
      this.customerSite = {
        id: item.customer_site_id,
        name: item.customer_site_name
      };
      this.address = item.bill_to_address;
      this.remarks = item.remarks;
      this.status = {
        value: item.status,
        text: item.status_meaning
      };
      this.billingStatus = {
        value: item.billing_status,
        text: item.billing_status_meaning
      };
      this.version = item.version;
      this.timesheetPeriod = item.month + ' - ' + item.year;
      this.getFmsTimesheetSummaryDetailsById();
    },
    getFmsTimesheetSummaryDetailsById() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        timesheet_summary_hdr_id: this.timesheetSummaryHdrId
      };
      this.loader = true;
      this.$store
        .dispatch(
          'fmsTimesheet/getFmsTimesheetSummaryDetailsById',
          this.payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.timesheetSummaryData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    editTimesheetSummaryRemarks() {
      const payload = {
        timesheet_summary_hdr_id: this.timesheetSummaryHdrId,
        remarks: this.remarks
      }
      this.$store
        .dispatch('fmsTimesheet/editTimesheetSummaryRemarks', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.getFmsTimesheetSummaryHdrById();
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    editTimesheetSummaryDetails() {
      if (this.isArrearRemarksMandatory()) {
        const timesheetSummaryArr = [];
        this.timesheetSummaryData.map(item =>
          timesheetSummaryArr.push({
            timesheet_summary_line_id: item.timesheet_summary_line_id,
            timesheet_summary_hdr_id: item.timesheet_summary_hdr_id,
            sales_agmt_comp_dtl_id: item.sa_comp_dtl_id,
            sales_agmt_comp_id: item.sa_comp_id,
            employee_name: item.employee_name,
            employee_code: item.employee_code,
            designation: item.designation,
            billing_working_days: item.billing_working_days,
            billing_monthly_off_days: item.billing_monthly_off_days,
            billing_monthly_holidays: item.billing_monthly_holidays,
            billing_total_paid_days: item.billing_total_paid_days,
            billing_overtime_days: item.billing_overtime_days,
            billing_remarks: item.billing_remarks,
            salary_working_days: item.salary_working_days,
            salary_monthly_off_days: item.salary_monthly_off_days,
            salary_monthly_holidays: item.salary_monthly_holidays,
            salary_total_paid_days: item.salary_total_paid_days,
            salary_overtime_days: item.salary_overtime_days,
            salary_remarks: item.salary_remarks,
            workday_hdr_id: item.workday_hdr_id,
            workday_shift_id: item.workday_shift_id,
            arrear_paid_days: item.arrear_paid_days,
            arrear_overtime_days: item.arrear_overtime_days,
            arrear_remarks: item.arrear_remarks,
            employee_type: item.employee_type
          })
        );
        const payload = {
          timesheet_summary_hdr: timesheetSummaryArr
        };
        this.$store
          .dispatch('fmsTimesheet/editTimesheetSummaryDetails', payload)
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.editMode = false;
              this.responseMsg = response.data.message;
              this.getFmsTimesheetSummaryDetailsById();
              this.editTimesheetSummaryRemarks();
              this.getSummaryData();
            } else {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    isArrearRemarksMandatory() {
      const filterData = this.timesheetSummaryData.filter(ele => ele.arrear_paid_days || ele.arrear_overtime_days);
      const finalData = filterData.filter(data => !data.arrear_remarks || data.arrear_remarks == "" || !data.employee_type)
      if (finalData.length) {
        alert("Please Fill Employee Type & Arrear Remarks!")
      } else {
        return true
      }
    },
    fmsTimesheetProcessUnProcess() {
      const processUnprocess =
        this.status.value === 'U'
          ? 'fmsTimesheetProcess'
          : 'fmsTimesheetUnprocess';
      const payload = {
        timesheet_summary_hdr_id: this.timesheetSummaryHdrId
      };
      this.$store
        .dispatch(`fmsTimesheet/${processUnprocess}`, payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.getFmsTimesheetSummaryHdrById();
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    deleteTimesheetSummaryDetailByLineId() {
      const selectedRows = this.timesheetSummaryData.filter(
        data => data.selectBox
      );
      let selectedDetailsLineIds = [];
      if (selectedRows.length > 0) {
        selectedDetailsLineIds = selectedRows.map(data => {
          return {
            timesheet_summary_line_id: data.timesheet_summary_line_id
          };
        });
      }
      if (selectedDetailsLineIds.length > 0) {
        const payload = {
          delete_timesheet: selectedDetailsLineIds
        };
        this.$store
          .dispatch(
            'fmsTimesheet/deleteTimesheetSummaryDetailByLineId',
            payload
          )
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.editMode = false;
              this.responseMsg = response.data.message;
              this.getFmsTimesheetSummaryDetailsById();
            } else {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    selectBoxChecked(flag, index) {
      this.timesheetSummaryData[index].selectBox = flag;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.EMPLOYEE_TYPE) {
        this.timesheetSummaryData[this.specIndex].employee_type_meaning =
          item.value_meaning;
        this.timesheetSummaryData[this.specIndex].employee_type =
          item.value_code;
      }
      this.timesheetSummaryData = JSON.parse(
        JSON.stringify(this.timesheetSummaryData)
      );
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'EMPLOYEE_TYPE') {
        this.timesheetSummaryData[index].employee_type = null;
        this.timesheetSummaryData[index].employee_type_meaning = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    closeUploadExcelModal() {
      this.getFmsTimesheetSummaryDetailsById();
      this.showUploadExcel = false;
    },
    addNewRow(index) {
      if (this.editMode) {
        this.timesheetSummaryData.splice(index + 1, 0, {
          timesheet_summary_line_id: 0,
          timesheet_summary_hdr_id: this.timesheetSummaryData[index]
            .timesheet_summary_hdr_id,
          sa_comp_dtl_id: this.timesheetSummaryData[index].sa_comp_dtl_id,
          sa_comp_id: this.timesheetSummaryData[index].sa_comp_id,
          billing_plan_name: this.timesheetSummaryData[index].billing_plan_name,
          sa_comp_name_real: this.timesheetSummaryData[index].sa_comp_name_real,
          sa_comp_name: this.timesheetSummaryData[index].sa_comp_name,
          employee_name: null,
          employee_code: null,
          designation: null,
          employee_type: null,
          employee_type_meaning: null,
          billing_working_days: 0,
          billing_monthly_off_days: 0,
          billing_monthly_holidays: 0,
          billing_total_paid_days: 0,
          billing_overtime_days: 0,
          billing_comb_paid_days: null,
          billing_remarks: null,
          salary_working_days: 0,
          salary_monthly_off_days: 0,
          salary_monthly_holidays: 0,
          salary_total_paid_days: 0,
          salary_overtime_days: 0,
          salary_comb_paid_days: null,
          salary_remarks: null,
          arrear_paid_days: 0,
          arrear_overtime_days: 0,
          arrear_remarks: null,
          workday_hdr_id: this.timesheetSummaryData[index].workday_hdr_id,
          workday_shift_id: this.timesheetSummaryData[index].workday_shift_id,
          shift_name: this.timesheetSummaryData[index].shift_name,
          shift_hour: this.timesheetSummaryData[index].shift_hour
        });
      }
    },
    removeRow(index) {
      this.timesheetSummaryData.splice(index, 1);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};